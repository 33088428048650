// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const _ = import.meta.globEager("./**/*_channel.js");

import "../css/index.css";
import "@fontsource-variable/inter";
import "@fontsource-variable/public-sans";

import Rails from "@rails/ujs";
// Alpine import
import Alpine from "alpinejs";
import focus from "@alpinejs/focus";

window.Alpine = Alpine;
Alpine.plugin(focus);
Alpine.start();
// // DOM Polyfills (Babel only covers ES6 features, not browser stuff)
// require("../javascript/polyfills.js")
import "../../webpacker/javascript/installOnDom.js";

// Webpack processes the SCSS, thus its presence here.
// import "../../webpacker/stylesheets/application.scss";

// Error monitoring
// import * as Sentry from "@sentry/browser"

// Sentry.init({
//   dsn:
//     "https://d03b49b54f70482384e57388708ed20a@o4504624549199872.ingest.sentry.io/4504624568795136"
// })

// import { partytownSnippet } from "@builder.io/partytown/integration";

// // Partytown
// window.addEventListener("DOMContentLoaded", (_) => {
//   const snippetText = partytownSnippet();
//   const el = document.createElement("script");
//   el.innerText = snippetText;
//   document.body.appendChild(el);
// });
